.room-card__add-marker-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  clip-path: circle();
  min-height: 2rem;
  min-width: 2rem;
  background-color: #0079C1;
  margin-left: 0.8rem;
  &:hover {
    cursor: grab;
  }
}

.delete-room-icon-wrap {
  display: flex;
  align-items: center;
  transform: translateY(10%);
}