.advanced-settings__wrap {
  display: flex;
  align-items: center;
  // justify-content: flex-start;
  margin-bottom: 0.5rem;
}

.advanced-settings__label {
  color: white;
  margin-right: 1rem;
  flex-grow: 1;
  max-width: 53%;
  min-width: 15rem;
}

.modal-info-center {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}