.room-types {
  &-list {
    padding: 0 10px 0 2px;
    &__item {
      align-items: center;
      .ant-checkbox-wrapper {
        margin-top: 3px;
        & span {
          transition: color .3s ease;
        }
        &-disabled {
          & span {
            color: $lightBtnBG !important;
          }
        }
      }
      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: $transparentDarkBlue !important;
          border-color: $lightBtnBG !important;
          &:after {
            border-color: $lightBtnBG;
          }
        }
      }
      &-title {
        @extend.text__wrapper;
        display: block;
        max-width: 10rem;
        font-size: 1rem;
      }
      &-input {
        width: 80%;
      }
      &-checkbox-group {
        display: flex;
        margin-bottom: 4px;
        .ant-checkbox-wrapper {
          width: 134px;
        }
        .ant-input {
          width: 3rem;
          margin-left: 1.5rem;
        }
        .icon-info {
          align-self: center;
          margin-left: 0.5rem;
        }
      }
      &-interval {
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
      }
      &-switch-group {
        width: 4.5rem;
        height: 30px;
        display: flex;
        align-items: center;
      }
      &-action-group {
        display: flex;
        height: 30px;
        align-items: center;
        align-self: center;
        .icon__edit-outline__svg {
          margin-right: 1rem;
        }
      }
      .icon__colorpicker {
        border-radius: 4px;
        &.color-selected {
          &:hover {
            background-image: url('../assets/images/icons/icon_colorpicker.svg');
          }
        }
      }
      &-action {
        display: flex;
        position: relative;
        &:not(:last-child) {
          margin-right: 14px;
        }
        &:hover {
          &:after {
            opacity: 1;
            content: '';
            position: absolute;
            top: calc(50% - 13px);
            left: calc(50% - 13px);
            width: 27px;
            height: 27px;
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 4px;
          }
        }
        &_disabled {
          display: flex;
          position: relative;
          opacity: 0.3;
          cursor: not-allowed;
          &:not(:last-child) {
            margin-right: 14px;
          }
        }
      }
    }
    &__icon-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      height: min-content;
      margin-right: 14px;
      &:hover {
        &::after {
          opacity: 1;
          content: '';
          position: absolute;
          top: calc(50% - 13px);
          left: calc(50% - 14px);
          width: 27px;
          height: 27px;
          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 4px;
        }
      }
    }
    &__item-tooltip {
      .ant-tooltip-inner {
        text-align: center;
        background: $tooltipBG;
        border-radius: 5px;
        color: $lightGray;
      }
    }

  }
}
