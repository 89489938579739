.pop_up-enter {
  opacity: 0;
}

.pop_up-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.pop_up-enter-done {
  opacity: 1;
}


.pop_up-exit {
  opacity: 1;
}

.pop_up-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.pop_up-exit-done {
  opacity: 0;
}


.pop_up-appear {
  opacity: 0;
}

.pop_up-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.pop_up-appear-done {
  opacity: 1;
}
