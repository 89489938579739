.qr-print-modal {
  width: 700px;
  height: 700px;
  &-disclaimer {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem 0 1.5rem 0.5rem;
    border-bottom: 0.5px solid $divider;
    &__text-wrapper {
      padding-left: 1rem;
    }
    &__title,
    &__subtitle {
      text-align: left;
    }
    &__title {
      line-height: 1;
      margin-bottom: 4px;
      font-size: 1rem;
      color: $lightGray;
      display: block;
    }
    &__subtitle {
      margin-bottom: 0;
    }
  }

  &-description {
    color: $lightGray;
    font-size: 1rem;
    padding: 1rem 0;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .card_list__header {
    margin-right: 0;
    div {
      margin-right: 0;
    }
  }

  .room-list-item {
    padding: 12px 16px;
    height: 42px;
    min-height: 42px;

    &__id {
      color: $labelText;
    }

    div:nth-child(4),
    div:nth-child(5) {
      border-right: 0;
    }

    &__title,
    &__floor {
      border-right: 1px solid $cardListDivider;
    }

    &__id,
    &__title,
    &__floor {
      @extend .text__wrapper;
    }
  }

  .table-item {
    display: flex;
    padding: 0.5rem 1.5rem;
    &__id {
      @extend .text__wrapper;
      color: $labelText;
      margin-right: 18px;
    }
    &__title {
      @extend .text__wrapper;
      border-right: 1px solid $cardListDivider;
      margin-right: 10px;
    }
    &__tags {
      @extend .text__wrapper;
      margin-right: 25px;
    }
  }
}
