@import '../../../../styles/constants.scss';

.user-groups-list {
  &__modal {
    width: 60% !important;
    max-width: 700px;
    height: 70% !important;
    max-height: 700px !important;
    padding-right: 20px !important;
    .icon__expand_svg {
      path {
        stroke: $blue;
      }
    }
  }
  &__group-wrapper {
    width: 100%;
    display: flex;
    border-radius: 5px;
    background-color: $topCardBG;
    padding: 0 25px;
    height: 70px;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all .2s ease;
    margin-bottom: 2px;
  }
  &__group-title {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    color: white;
    white-space: nowrap;
    flex: 1;
    padding-right: 20px;
    border-right: 1px solid $cardListDivider;
    font-family: 'DIN Pro';
  }
  &__group-description {
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    color: $mainText;
    flex: 2;
  }
  &__office-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    border-radius: 5px;
    background-color: $topCardBG;
    padding: 0 25px;
    align-items: center;
    margin-bottom: 2px;
    span {
      &:first-child {
        flex: 1;
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        color: white;
        border-right: 1px solid $cardListDivider;
        font-family: 'DIN Pro';
      }
      &:nth-child(2) {
        padding-left: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        color: $mainText;
        flex: 1.3;
      }
    }
  }
  &__zones-wrapper {
    counter-increment: list-number;
    display: flex;
    flex-direction: column;
  }
  &__zone-wrapper {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    height: 40px;
    padding: 10px 25px;
  }
  &__zone-title {
    width: 100%;
    color: $mainText;
    flex: 10;
  }
  &__zone-index {
    color: $secondaryText;
    flex: 0.5;
  }
}
