.form-enter {
  height: 0;
}

.form-enter-active {
  height: 200px;
  transition: height .3s ease-out;
}

.form-enter-done {
  height: 200px;
}

.form-exit {
  height: 200px;
}

.form-exit-active {
  height: 0;
  transition: height .3s ease-out;
}

.form-exit-done {
  height: 0;
}
