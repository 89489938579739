.sub_form-enter {
  height: 0;
  opacity: 0;
}

.sub_form-enter-active {
  height: fit-content;
  opacity: 1;
  transition: height .2s ease-out, opacity .4s ease-in;
}

.sub_form-enter-done {
  height: fit-content;
  opacity: 1;
}

.sub_form-exit {
  height: fit-content;
  opacity: 1;
}

.sub_form-exit-active {
  height: 0;
  opacity: 0;
  transition: height .2s ease-out;
}

.sub_form-exit-done {
  height: 0;
  opacity: 0;
}

.sub_form-appear {
  height: 0;
  opacity: 0;
}

.sub_form-appear-active {
  height: fit-content;
  opacity: 1;
  transition: height .2s ease-out, opacity .4s ease-in;
}

.sub_form-appear-done {
  height: fit-content;
  opacity: 1;
}
