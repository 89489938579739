@import '~antd/dist/antd.css';
@import 'text';
@import 'constants';
@import 'cardList';
@import 'scrollbar';
@import 'button';
@import 'containers';
@import 'screens/officeEditingScreen';
@import 'floors-info';
@import 'zones-info';
@import 'room-types';
@import 'text-modal';
@import 'cardList';
@import 'scrollbar';
@import 'button';
@import 'table-list';
@import 'qrPrintModal';
@import 'tableFormModal';
@import 'roomFormModal';
@import 'tagsSelector';
@import 'filter-input';
@import '../components/Notifications/notifications.scss';

@font-face {
  font-family: 'DIN Pro';
  src: url('../assets/fonts/DINPro.eot');
  src: local('DIN Pro'), local('DINPro'),
    url('../assets/fonts/DINPro.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/DINPro.woff') format('woff'),
    url('../assets/fonts/DINPro.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Light';
  src: url('../assets/fonts/DINPro-Light.eot');
  src: local('DIN Pro Light'), local('DINPro-Light'),
    url('../assets/fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/DINPro-Light.woff') format('woff'),
    url('../assets/fonts/DINPro-Light.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Bold';
  src: url('../assets/fonts/DINPro-Bold.eot');
  src: local('DIN Pro Bold'), local('DINPro-Bold'),
    url('../assets/fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/DINPro-Bold.woff') format('woff'),
    url('../assets/fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Icons';
  src: url('../assets/fonts/fontello.eot');
  src: local('Icons'), local('Icons'),
    url('../assets/fonts/fontello.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/fontello.woff') format('woff'),
    url('../assets/fonts/fontello.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.wait {
  cursor: wait !important;
  & .btn {
    cursor: wait !important;
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: 'DIN Pro Light';
  color: $white;
  background-color: $darkBlueBG;
  min-height: 400px;
  width: 100%;
  max-width: 100%;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 18px;
}

h1 {
  font-family: 'DIN Pro';
  line-height: 40px;
  display: block;
  color: $white;
  font-size: $h1TextSize;
  margin: 0;
}

.flex_container__align_items__center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_container_column__align_items__center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.margin_top {
  margin-top: 15px;
}

.padding_bottom {
  padding-bottom: 15px;
}

.margin_right {
  margin-right: 7px;
}

.margin_vertical {
  margin: 20px auto;
}

.opacity_half {
  opacity: 0.5;
  transition: opacity 0.3s ease-out;
}

.flex_container__flex_3 {
  flex: 3;
}

.flex_container__flex_1 {
  flex: 1;
}

.flex_container__flex_2 {
  flex: 2;
}

.margin_bottom {
  margin-bottom: 15px;
}

.margin_right__no {
  margin-right: 0;
}

.border_bottom {
  border-bottom: 0.5px solid $divider;
}

.border_right {
  border-right: 0.5px solid $darkDivider;
}

.full_height {
  height: 100%;
}

.full_width {
  width: 100%;
}

.min_width__100 {
  min-width: 100%;
}

.align_self__center {
  align-self: center;
}

.h1__error_page {
  font-family: 'DIN Pro Bold';
  line-height: 200px;
  color: $subtitle;
  font-size: 180px;
  user-select: none;
}

.spin_placeholder {
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_h1 {
  margin: 20px 0 30px;
  h1 {
    margin-bottom: 4px;
  }
}

.app_BG {
  display: flex;
  flex-direction: column;
  background: $darkBlueBG;
  position: relative;
  flex: 4;
  width: 100%;
  max-width: 100%;
  min-width: 800px;
  height: 100vh;
  min-height: 500px;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  background: $cardBG;
  height: 9%;
  min-height: 62px;
  max-height: 85px;
  font-size: $mainTextSize;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.nav_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-grow: 1;
  height: 100%;
}

.nav_link {
  font-family: 'DIN Pro';
  user-select: none;
  cursor: pointer;
  line-height: 25px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:active {
    color: $blueLink;
  }
  &:hover {
    color: $blueLink;
    text-shadow: 0 0 4px rgba(64, 169, 255, 0.3);
  }
  margin-left: 4.4%;
  &:first-child {
    margin-left: 0;
  }
}

.nav_link__inactive {
  @extend .nav_link;
  color: $mainText;
  a &:hover {
    color: $blueLink;
  }
}

.nav_link__active {
  @extend .nav_link;
  color: $white;
  a &:hover {
    color: $blueLink;
  }
}

.logo_placeholder {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  max-width: 350px;
  height: 100%;
  background: $cardBG;
  cursor: pointer;
  padding: 7px 0 15px;
  & svg {
    max-width: 220px;
  }
}

.logo {
  display: block;
  box-sizing: border-box;
}

.user_info {
  width: 19.4%;
  min-width: 198px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 2.5% 10px 0;
  cursor: pointer;
  position: relative;
}

.user_info__inner {
  box-sizing: border-box;
  margin: 0 10px 0 40px;
  justify-content: flex-end;
  height: 100%;
  max-height: 100%;
  &:hover .user_name {
    color: white;
    transition: text-shadow 0.2s ease, color 0.2s ease;
    text-shadow: 0 0 3px $semiTransparentBlue;
  }
}

.user_info_pop_up {
  width: auto;
  min-width: 220px;
  max-width: 300px;
  height: initial;
  position: absolute;
  bottom: -250px;
  right: -18px;
  background-color: $cardBG;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 5;
  padding: 20px;
}

.user_info_pop_up__triangle {
  position: absolute;
  right: 12px;
  top: -14px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid $cardBG;
}

.user_name {
  color: $accentText;
  font-size: 16px;
  margin-right: 7%;
  user-select: none;
  transition: text-shadow 0.2s ease, color 0.2s ease;
}

.user_avatar__normal {
  width: 42px;
  height: 42px;
  margin-right: 3%;
}

.user_avatar__small {
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 7%;
}

.user_avatar {
  background-size: cover;
  border-radius: 50%;
}

.screen__two_sidebars {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'main main main card_top' 'main main main card_bottom';
  gap: 30px;
  min-height: 765px;
  height: 91%;
  margin: 0 auto;
  padding: 2.8% 2.5%;
}

.screen__one_sidebar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'main main main card_top' 'main main main card_top';
  gap: 30px;
  min-height: 765px;
  height: 91%;
  margin: 0 auto;
  padding: 2.8% 2.5%;
}

.screen__no_grid {
  display: flex;
  height: 91%;
  margin: 0 auto;
  padding: 35px;
  width: 100%;
  max-width: 1700px;
  min-width: 1px;
  box-sizing: border-box;
  justify-content: center;
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  background: $cardBG;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 0 20px 20px 25px;
  overflow: auto;
  height: 100%;
  max-height: 100%;
}

.card__small {
  @extend .card;
  overflow: visible;
  padding: 0 30px 20px 30px;
  border: 0.5px solid transparent;
  transition: border 0.1s ease-in-out;
  min-width: 1px;
  min-height: 1px;
  max-height: 100%;
}

.card__small__active {
  @extend .card__small;
  animation-name: border_pulse;
  transition: border-color 0.3s ease-out;
  -webkit-animation: border_pulse 2s infinite;
  animation: border_pulse 2s infinite;
}

@-webkit-keyframes border_pulse {
  0% {
    border-color: #623434;
  }
  40% {
    border-color: #824a4a;
  }
  100% {
    border-color: #623434;
  }
}

@keyframes border_pulse {
  0% {
    border-color: #623434;
  }
  40% {
    border-color: #824a4a;
  }
  100% {
    border-color: #623434;
  }
}

.card__auth {
  @extend .card;
  height: 360px;
  max-height: 360px;
  width: 300px;
  padding: 20px 20px 30px 20px;
}

.card__top {
  @extend .card;
  grid-area: card_top;
}

.card__bottom {
  @extend .card;
  grid-area: card_bottom;
}

.main {
  @extend .card;
  grid-area: main;
  padding: 16px 60px 30px;
  overflow: hidden;
  width: 100%;
}

.main_card__subtitle {
  margin: 20px 0;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  &__office-detail {
    margin-bottom: 10px;
  }
}

.main__card_view {
  @extend .main;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main_title__card_view {
  display: flex;
  flex-direction: row;
  height: 64px;
  min-height: 64px;
  align-items: center;
  background: $cardBG;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 18px 30px 16px 27px;
  margin-bottom: 30px;
}

.main_title__wrapper {
  display: block;
}

.main_body__card_view {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  flex: 1;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'main-card-view-top' 'main-card-view-bottom';
}

.main__card_view__top {
  grid-area: main-card-view-top;
  display: flex;
  flex-direction: column;
}

.main__card_view__grid_block {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'left right';
  grid-gap: 30px;
  gap: 30px;
  & {
    .left {
      grid-area: left;
    }

    .right {
      grid-area: right;
    }
  }
}

.main__card_view__bottom {
  grid-area: main-card-view-bottom;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'left right';
  grid-gap: 30px;
  gap: 30px;
  & {
    .left {
      grid-area: left;
    }

    .right {
      grid-area: right;
    }
  }
}

.card_title {
  font-family: 'DIN Pro';
  display: flex;
  align-items: center;
  user-select: none;
  height: 35px;
  min-height: 35px;
  margin: 16px 0 10px;
}

.title_block__subtitle {
  display: flex;
  justify-content: space-between;
  margin: 20px 17px 40px 20px;
}

.overflow_scroll {
  overflow: scroll;
  padding-right: 15px;
}

.overflow_visible {
  overflow: visible;
}

.notification__time_stamp {
  display: block;
  font-size: $smallTextSize;
  color: $blue;
  text-align: right;
  margin-top: 3px;
  padding-bottom: 4px;
  cursor: default;
}

.notification__item__animation {
  overflow: hidden;
}

.notification__subtitle_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.link_block {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  width: 49px;
  border-radius: 0 15px 0 0;
  background-color: $linkBlockBG;
}

.lecture_info__label {
  display: block;
  font-size: $secondaryTextSize;
  color: $mainText;
  margin-bottom: 8px;
}

.lecture_info__value {
  display: block;
  font-size: $secondaryTextSize;
  color: $white;
}

.lecture_info__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

.lecture_info {
  border-bottom: 0.5px solid $divider;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.lecture_info__left {
  padding-right: 3%;
  flex: 1;
}

.lecture_info__right {
  padding-left: 3%;
  flex: 1;
}

.third_elem__no_border {
  &:nth-child(3) {
    border-bottom: 0;
  }
}

.photo_preview {
  height: 60px;
  width: 60px;
  max-width: 60px;
  margin: 2px 0 10px 2px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  & .icon__delete {
    position: absolute;
    top: 0px;
    right: -14px;
  }
}

.list__title {
  display: flex;
  & .large_btn__title {
    flex: 1;
  }
}

.list__subtitle {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 11px;
  & span {
    display: block;
  }
}

.list__subtitle__item {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  & .secondary_text {
    padding-right: 6px;
  }
}

.event_form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  & form {
    display: flex;
    height: 100%;
  }
  position: relative;
  overflow: auto;
  height: 100%;
}

.event_form__main {
  flex: 1;
  padding-right: 15px;
  border-right: 0.5px solid $divider;
  min-width: 200px;
}

.event_form__lecture {
  overflow: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-left: 30px;
  position: relative;
  & .lecture_form {
    border-top: 0.5px solid $divider;
  }
}

.user_form__edit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 23px 20px 20px 20px;
  position: relative;
  & form {
    display: flex;
    flex-direction: row;
    width: 100%;
    & div {
      flex: 1;
    }
  }
  .validation_block {
    flex-direction: column;
  }
}

.kiosk_form__edit {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 23px 20px 20px 20px;
  position: relative;
  & form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

.kiosk_form__new {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 17px 26px 0 0;
  position: relative;
  height: 100%;
  & .container_form__right {
    min-width: 100%;
    padding: 0 2px;
  }
  & .container_form__left {
    flex: none;
    padding: 0 2px;
    overflow: visible;
  }
  & .button_container__content_right {
    flex: 1;
  }
  & form {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.lecture_form {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 3% 0 20px;
  margin-right: 27px;
  position: relative;
  & form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
.basic_form {
  max-width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 2%;
  margin-right: 27px;
  position: relative;
  & .info__block {
    padding-top: 0;
    border: 0;
    margin-right: 35px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.lecture_form__wrapper {
  min-height: 50%;
  padding-top: 3%;
}

.lecture_form__button_wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-top: 30px;
  bottom: 0;
  right: 0;
}

.form_table__block {
  @extend .flex_container;
  margin: 12px 0 10px 12px;
}

// markers
.round_marker {
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

.green_round_marker {
  @extend .round_marker;
  background-color: $greenMarker;
}

.yellow_round_marker {
  @extend .round_marker;
  background-color: $yellowMarker;
}

.purple_round_marker {
  @extend .round_marker;
  background-color: $purple;
}

.blue_round_marker {
  @extend .round_marker;
  background-color: $blueMarker;
}

.round_marker_margin {
  margin-right: 10px;
}

// PopUp container
.pop_up {
  height: fit-content;
  width: min-content;
  max-width: 100%;
  padding: 18px 21px;
  border-radius: 15px;
  background-color: $darkBlueBG;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 240px;
  & .pop_up__description {
    align-self: flex-start;
    margin: 10px 0 20px;
  }
  & .list__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 13px 0 13px;
    &:first-child {
      padding: 8px 0 13px;
    }
    & .main_text,
    & .main_text__red,
    & .main_text__green {
      margin-right: 14px;
    }
  }
  & :nth-last-child(2) {
    border: 0;
  }
  box-shadow: 0 0 10px $semiTransparentBlack;
}

.padding-top {
  margin-top: 2%;
}

.dark_blue__background {
  background-color: $darkBlueBG;
}

.pop_up__description {
  @extend .secondary_text;
}

.lectures_list {
  @extend .container__list;
}

.auth__logo {
  height: fit-content;
  width: 100%;
  margin: 40px 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table__pop_up {
  background-color: red;
  height: 20px;
  position: absolute;
}

.table_pop_up__block {
  @extend .flex_container;
  @extend .flex_container__flex_1;
  min-width: 26px;
}

.card_list__side_info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
}

.container__list__simple {
  flex: 1;
  width: 100%;
  user-select: none;
  padding: 0 24px 0 2px;
  position: relative;
}

.container__justify__center {
  @extend .card_list__side_info;
  justify-content: center !important;
}

//filter tags

.filter_tag__block {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 30px;
  margin: 10px 17px 5px 0;
}

.filter_tag__block__margin_left {
  @extend .filter_tag__block;
  align-items: center;
  margin: 10px 17px 20px 20px;
}

.filter_tag__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  border-radius: 15px;
  padding: 0 15px;
  margin-right: 15px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
  font-size: $smallTextSize;
  background-color: $topCardBG;
  &:last-child {
    margin-right: 0;
  }
}

.filter_tag__item__active {
  @extend .filter_tag__item;
  color: white;
  border: 0.5px solid $blue;
  &:hover {
    @include btn_transition(0.3s);
    box-shadow: 0 0 6px rgba(0, 121, 193, 0.7);
  }
}

.filter-tag__item_no-hover {
  @include btn_transition(0.3s);
  @extend .filter_tag__item;
  color: $mainText;
  cursor: default;
  border: 0.5px solid transparent;
  margin-bottom: 10px;
}

.filter_tag__item__inactive {
  @extend .filter-tag__item_no-hover;
  &:hover {
    @include btn_transition(0.3s);
    transition: color 0.3s ease-in;
    box-shadow: 0 0 6px rgba(0, 121, 193, 1);
    color: white;
  }
}

.margin_top__small {
  margin-top: 2px;
}

#range-picker {
  width: auto !important;
  bottom: 5px;
  position: absolute;
}

.cursor_default {
  cursor: default;
  user-select: none;
}

.cursor_pointer {
  cursor: pointer;
  user-select: none;
}

.card__bottom {
  .list__item {
    justify-content: space-between;
    user-select: none;
  }
}

// <--- Screens ScreensCardSideBar at SideBar --->

.screen_list__item {
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  margin-right: 10px;
  border-bottom: 0.1px solid $divider;
  cursor: pointer;
  overflow-x: hidden;
  position: relative;
  &:first-child {
    border-top: 0.1px solid $divider;
  }
  & .list__item__title {
    @extend .text__wrapper;
    flex: 1;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  & .list__item__subtitle {
    @extend .text__wrapper;
    font-size: 12px;
    color: $mainText;
    flex: 1;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.screen_list__item__inactive {
  @extend .screen_list__item;
  & .list__item__title {
    opacity: 0.3 !important;
  }
  & .list__item__subtitle {
    opacity: 0.3 !important;
  }
}

.screen_list__item__content_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1px;
  width: 100%;
}

.screen_list__item__title_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 1px;
}

.screen_list__item_check {
  border: 0.5px solid $lightBtnBG;
  background-color: transparent;
  min-width: 20px;
  max-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}

.screen_list__item__subtitle {
  @extend .text__wrapper;
  @extend .secondary_text;
  font-size: $smallTextSize;
}

.screen_list__item_check__active {
  @extend .screen_list__item_check;
  background-color: $blue;
  border: 0.5px solid $blue;
  transition-delay: 0.1s;
  transition: box-shadow 0.3s ease-out;
  -webkit-animation: box_shadow_pulse_blue 1.5s infinite;
  animation: box_shadow_pulse_blue 1.5s infinite;
  animation-name: box_shadow_pulse_blue;
}

@-webkit-keyframes box_shadow_pulse_blue {
  0% {
    box-shadow: 0 0 2px $semiTransparentBlue;
  }
  50% {
    box-shadow: 0 0 5px $semiTransparentBlue;
  }
  100% {
    box-shadow: 0 0 2px $semiTransparentBlue;
  }
}

@keyframes box_shadow_pulse_blue {
  0% {
    box-shadow: 0 0 2px $semiTransparentBlue;
  }
  50% {
    box-shadow: 0 0 5px $semiTransparentBlue;
  }
  100% {
    box-shadow: 0 0 2px $semiTransparentBlue;
  }
}

.screen_list__button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-animation: fade 0.5s;
  animation: fade 0.5s;
  animation-name: fade;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 15px;
  right: 0;
  z-index: 2;
  padding: 6px 0;
  background: rgb(25, 31, 40);
  background: linear-gradient(
    90deg,
    rgba(25, 31, 40, 0) 0%,
    rgba(25, 31, 40, 0.6166841736694677) 1%,
    rgba(25, 31, 40, 0.8883928571428571) 2%,
    rgba(25, 31, 40, 1) 4%,
    rgba(25, 31, 40, 1) 100%
  );
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.screen_list__button {
  display: flex;
  color: $white;
  height: 28px;
  padding: 2px 8px 3px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  user-select: none;
}

.screen_list__button__blue {
  @extend .screen_list__button;
  background-color: $blue;
}

.screen_list__button__red {
  @extend .screen_list__button;
  background-color: $red;
}

.screen_list__button_gray {
  @extend .screen_list__button;
  border: 1px solid $lightBtnBG;
  color: $mainText;
  background-color: $cardBG;
}

// <-- PopUp -->

.pop_up__angle__small {
  position: absolute;
  border-radius: 10px;
  background-color: $tooltipBG;
  padding: 10px;
  top: 37px;
  right: 0;
  z-index: 3;
  min-height: 85px;
  max-height: 200px !important;
  width: 200px;
  box-shadow: 0 0 10px rgb(14, 19, 26), 0 -1px 6px rgba(14, 19, 26, 0.5);
  user-select: none;
  & .screen_list__item:first-child {
    padding-top: 10px;
    border-top: 0;
  }
  & .screen_list__item:last-child {
    padding-bottom: 10px;
    border-bottom: 0;
  }
}

.pop_up__angle__small::after {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 10px;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent $tooltipBG transparent;
}

.pop_up__angle__small__wrapper {
  display: flex;
  overflow: auto;
  width: 100%;
  min-height: 1px;
  flex: 1;
  height: 100% !important;
}

.card_title__top_btn_padding {
  padding-right: 17px;
}

.side_bar__notifications {
  @extend .flex_container__column;
  padding-right: 14px;
}

#root {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.notifications_panel {
  flex: 1;
  box-sizing: border-box;
  background-color: $cardBG;
  display: flex;
  flex-direction: column;
  border-left: 0.5px solid $divider;
  max-width: 400px;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.notifications_panel__title_block {
  z-index: 2;
  position: relative;
  height: 85px;
  background-color: inherit;
  display: flex;
  align-items: center;
  border-left: 0.5px solid $divider;
  & .large_btn__title {
    font-family: 'DIN Pro';
    margin-top: 10px;
    margin-left: 20px;
  }
}

.notifications_panel__content {
  display: flex;
  flex-direction: column;
  background-color: inherit;
  height: 100%;
  padding: 30px 20px;
  overflow: scroll;
}

.display_none {
  display: none;
}

.animated_width > div {
  height: 100%;
}