.fade_block-enter {
  display: flex;
  flex: 1;
  opacity: 0;
  width: 100%;
}

.fade_block-enter-active {
  width: 100%;
  opacity: 1;
  transition: opacity 500ms ease-out;
  .ScrollbarsCustom-Content & form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.fade_block-enter-done {
  width: 100%;
  opacity: 1;
  .ScrollbarsCustom-Content & form {
    display: flex;
    flex: 1;
    flex-direction: column !important;
  }
}

.fade_block-exit {
  width: 100%;
  opacity: 1;
}

.fade_block-exit-active {
  width: 100%;
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.fade_block-exit-done {
  width: 100%;
  opacity: 0;
}

.fade_block-appear {
  width: 100%;
  opacity: 0;
  .ScrollbarsCustom-Content & form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.fade_block-appear-active {
  width: 100%;
  opacity: 1;
  transition: opacity 500ms ease-out;
  .ScrollbarsCustom-Content & form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

}

.fade_block-appear-done {
  width: 100%;
  opacity: 1;
  .ScrollbarsCustom-Content & form {
    display: flex;
    flex: 1;
    flex-direction: column !important;
  }
}
