.tag {
  display: inline-flex;
  line-height: 1;
  align-items: center;
  margin-right: 10px;
  padding: 7px 11px;
  background: $topCardBG;
  border-radius: 30px;
  margin-bottom: 10px;
  border: 1px solid $topCardBG;
  cursor: pointer;
  font-size: $smallTextSize;
  transition: 0.2s border-color ease;
  &-selected {
    border-color: $blue;
  }
}

.tags-selector {
  padding: 13px 11px;
  background-color: $darkBlueBG;
  border-radius: 4px;
  flex: 1;
  padding-right: 5px;
  height: 160px;

  &__empty-placeholder {
    color: $secondaryText;
    margin-top: -5px;
  }

  .ScrollbarsCustom-Content {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    display: block;
  }

  .tag {
    background: $cardBG;
    border: 1px solid $cardBG;
    &-selected {
      border-color: $blue;
    }
  }
}
