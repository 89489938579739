.table-list {
  &__wrapper {
    padding: 1.25rem 0 1.25rem 4.5rem;
    .card_list__header {
      margin-right: 0;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    &__checkbox {
      margin-right: 18px;
    }
    &__title {
      @extend.text__wrapper;
      font-size: 1rem;
      font-weight: bold;
      margin-right: 30px;
      border-right: 1px solid $cardListDivider;
      line-height: 41px;
      //   max-width: 5rem;
    }
    &__tags {
      @extend.text__wrapper;
      font-size: 1rem;
      line-height: 41px;
      margin-right: 20px;
      //   max-width: 14rem;
    }
    &__rating {
      display: flex;
      align-items: center;
      border-right: none !important;
      margin-right: 15px;
      &-icon {
        margin-right: 5px;
      }
    }
    &__status {
      margin-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
      white-space: nowrap;
      .icon-status {
        display: inline-block;
        width: 0.5rem;
        min-width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.25rem;
        border-radius: 50%;
        background: $red;
        &-available {
          background: $labelText;
        }
      }
    }
    &__photos {
      display: flex;
      &-item {
        width: 42px;
        height: 42px;
        &:not(:last-child) {
          margin-right: 5px;
        }
        &-has-more {
          padding: 0;
          border: 0;
          border-radius: 5px;
          cursor: pointer;
          position: relative;
          span {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.4);
            line-height: 42px;
            font-size: 1rem;
            font-weight: 500;
          }
        }
        img {
          border-radius: 5px;
        }
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .icon__print,
      .icon__edit-outline__svg {
        margin-right: 1rem;
        position: relative;
        height: 16px;
        &:hover:after {
          opacity: 1;
          content: '';
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          width: 24px;
          height: 24px;
          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 4px;
        }
      }

      .icon__print svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
