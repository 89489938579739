.text-modal {
  width: 350px;
  height: 350px;
  textarea {
    height: 100%;
  }
  &__title {
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid $cardListDivider;
    margin-bottom: 1rem;
    line-height: 20px;
    padding-bottom: 10px;
  }
  &__subtitle {
    color: $lightGray;
  }
  &__hint {
    margin-bottom: 1rem;
  }
  &__btn {
    margin: 1rem auto 0;
  }
}
