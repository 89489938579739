.user_form-enter {
  height: 0;
  margin: 0 14px;
}

.user_form-enter-active {
  height: auto;
  margin: 16px 14px;
  transition: height 500ms ease-out, margin 400ms ease-out;
}

.user_form-enter-done {
  height: auto;
  margin: 16px 14px;
}

.user_form-exit {
  height: auto;
  margin: 16px 14px;
}

.user_form-exit-active {
  height: 0;
  opacity: 0;
  margin: 0 14px;
  transition: height 500ms ease-out, margin 400ms ease-out;
}

.user_form-exit-done {
  height: 0;
  display: none !important;
  margin: 0 14px;
}

.user_form-appear {
  height: 0;
  display: none;
  margin: 0 14px;
}

.user_form-appear-active {
  height: auto;
  margin: 0 14px;
  transition: height 500ms ease-out, margin 400ms ease-out;
}
