.table-form-modal {
  width: 550px;
  height: 90%;
  min-height: 685px;
  &__title {
    @extend .modal_card__title;
    @extend .border_bottom;
    margin-right: 10px;
  }
  &__form-title {
    font-size: $secondaryTextSize;
    color: $lightGray;
    text-align: left;
    display: block;
    margin: 16px 0 12px;
    .table-form-modal &:first-child {
      margin: 0 0 6px 0;
    }
  }
  .ant-form-item-label {
    white-space: initial;
    line-height: 17px;
  }
  &-actions {
    margin: 20px 10px 0 0;
    display: flex;
    justify-content: flex-end;
  }
  &__form-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    padding: 0 10px 0 2px;
  }
}

.custom-label {
  color: $labelText;
  margin: 0.5rem 0;
}
