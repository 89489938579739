@import '../../styles/constants.scss';

.marker__tooltip {
  color: black;
  position: absolute;
  background-color: $lightGray;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  cursor: default !important;
  width: 240px;
  animation: cssAnimation 0.3s ease 0.2s forwards;
  opacity: 0;
  z-index: 20;
  span {
    display: block;
  }
  .title {
    font-size: $mainTextSize;
  }
  .subtitle {
    margin-top: 4px;
    font-family: 'DIN Pro';
    font-size: $smallTextSize;
    letter-spacing: 0.2px;
  }
}


@keyframes cssAnimation {
  to {
    opacity: 1;
  }
}
