.zones-list {
  padding: 0 10px 0 2px;
  &__item {
    transition: height .2 ease;
    &-title {
      flex: 1 1 0%;
      color: $subtitle;
      height: 100%;
      line-height: 41px;
      font-size: $mainTextSize;
      text-align: left;
      padding-right: 32px;
      box-sizing: border-box;
    }
    &-whitelist {
      @extend .text__wrapper;
      flex: 1.5;
      display: flex;
      align-items: center;
      max-height: 100%;
      overflow: auto;
      margin-right: 30px;
      padding: 2px;
      .ant-select {
        padding: 0;
      }
      .ant-select-selection {
        width: 100%;
      }
    }
    &-action {
      display: flex;
      position: relative;
      &:not(:last-child) {
        margin-right: 14px;
      }
      &:hover {
        &:after {
          opacity: 1;
          content: '';
          position: absolute;
          top: calc(50% - 13px);
          left: calc(50% - 14px);
          width: 27px;
          height: 27px;
          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 4px;
        }
      }
      &_disabled {
        display: flex;
        position: relative;
        opacity: 0.3;
        cursor: not-allowed;
        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }
    &-input {
      width: 95%;
      margin-left: 2px;
    }
  }
}
