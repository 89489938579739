.lecture-enter {
  height: 0;
  padding: 0 !important;
  display: none;
}
.lecture-enter-active {
  height: auto;
  padding: 3% 0 20px !important;
  transition: height 400ms ease-out, padding 400ms ease-out;
}

.lecture-enter-done {
  height: auto;
  padding: 3% 0 20px !important;
}

.lecture-exit {
  height: auto;
  padding: 3% 0 20px !important;
}
.lecture-exit-active {
  height: 0;
  padding: 0 !important;
  opacity: 0;
  transition: height 400ms ease-out, padding 400ms ease-out;
}

.lecture-exit-done {
  height: 0;
  padding: 0 !important;
  display: none !important;
}

.lecture-appear {
  height: 0;
  padding: 0 !important;
  display: none;
}

.lecture-appear-active {
  height: auto;
  padding: 3% 0 20px !important;
  transition: height 400ms ease-out, padding 400ms ease-out;
}

.lecture-appear-done {
  height: auto;
  padding: 3% 0 20px !important;
}
