@import "constants";

.flex_container__no_wrap {
  flex-wrap: nowrap !important;
}

.flex_container__space_between {
  justify-content: space-between;
}

.flex_container__column__flex_end {
  align-items: flex-end;
}

.flex_container__column__flex_center {
  align-items: center;
}

.flex_container__center {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_container__table_charts {
  @extend .flex_container;
  flex: 1;
  display: flex;
  margin-top: 20px;
  & .filter__tabs {
    width: 100%;
  }
  & .flex_container .flex_container__flex_1{
    width: 30%;
  }
  & .flex_container .flex_container__flex_1 .chart_line__small{
    padding-right: 20px;
  }
  & .flex_container .flex_container__flex_1:last-child .chart_line__small{
    padding-right: 0;
  }
  & .flex_container {
    width: 100%;
    justify-content: space-between;
  }
}

.container__pop_up {
  @extend .flex_container__column;
  margin-top: 10px;
  margin-right: 27px;
  flex: 1;
  max-width: 30%;
}

.container__room {
  @extend .flex_container__column;
  width: 25%;
  max-width: 25%;
  min-width: 25%;
  margin-top: 10px;
  overflow: auto;
  margin-right: 20px;
  & .card_list_item__reserve h1 {
    flex: 5;
    justify-content: flex-start !important;
    display: block;
  }
}

.container__table {
  @extend .flex_container__column;
  width: 20%;
  min-width: 20%;
  max-width: 20%;
  margin-top: 10px;
  margin-right: 20px;
  overflow: auto;
  overflow-x: hidden;
  & .card_list_item__reserve h1 {
    justify-content: flex-start !important;
  }
  & .list__subtitle {
    padding-right: 20px;
  }
}

.container__floor {
  @extend .flex_container__column;
  width: 18%;
  margin-top: 10px;
  overflow: auto;
  margin-right: 20px;
}

.container__floor,
.container__room,
.container__table {
  & .large_btn__title {
    margin-bottom: 10px;
  }
}

.container__right_side {
  overflow: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-left: 30px;
  padding-right: 2px;
  overflow-x: hidden;
}

.container__scroll {
  overflow: scroll;
}

.container__list {
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 175px;
  padding: 3px 17px 3px 3px;
  user-select: none;
}

.container_form {
  &__left {
    overflow: auto;
  }
  &__right {
    max-width: 50%;
    padding: 0 2px 0 3%;
    position: relative;
    overflow: visible;

    & .button_container__content_right {
      right: 0;
    }

    & form {
      display: flex;
      flex-direction: column;
    }
  }
}

.button_container__save_zone {
  @extend .button_container__content_right;
  margin: 10px 0;
}

.button_container__save_floor {
  @extend .button_container__content_right;
  margin: 10px 0;
}

.button_container__close_floors_panel,
.button_container__close_zones_panel {
  @extend .button_container__content_right;
  max-height: 35px;
}

.container__left_side {
  flex: 1;
  padding: 2px 30px 2px 2px;
  border-right: .5px solid $divider;
  min-width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  & form {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.container__left_side__new_user_form {
  flex: 1;
  padding: 2px;
  border-right: .5px solid $divider;
  min-width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  & form {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.container__left_side__new_user_form__inner {
  @extend .container__left_side;
  margin-top: 20px;
  padding: 0 13px 0 0;
  border-right: 0;
  & > .ScrollbarsCustom > .ScrollbarsCustom-Wrapper > .ScrollbarsCustom-Scroller > .ScrollbarsCustom-Content > form {
    padding-left: 2px;
    //padding-right: 17px;
    flex: 1;
  }
}

.container__left_side__user_group_form {
  @extend .container__left_side;
  padding-right: 30px;
  border-right: 0;
  & > .ScrollbarsCustom > .ScrollbarsCustom-Wrapper > .ScrollbarsCustom-Scroller > .ScrollbarsCustom-Content > form {
    padding-left: 2px;
    padding-right: 20px;
    flex: 1;
  }
}

.container__multi_input {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  & .ant-form-item {
    width: 50%;
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.flex_container {
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  & form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.container__left_side form {
  flex-wrap: nowrap !important;
}

.flex_container__room_form {
  @extend .flex_container;
  margin: 15px 0;
  padding: 0 15px;
  border-bottom: .5px solid $divider;
}

.flex_container__column {
  @extend .flex_container;
  flex-direction: column;
  & form {
    flex-direction: column;
  }
  & .info__block {
    border: 0;
  }
}

.flex_container__row {
  @extend .flex_container;
  & form {
    flex-direction: row;
  }
}

.flex_container__tables_list {
  @extend .flex_container__column;
  margin: 16px 0 16px 30px;
  & .flex_container {
    flex: 1;
  }
}

.align_self__center {
  align-self: center;
}

.align_self__flex_start {
  align-self: flex-start;
}

.modal_bg {
  backdrop-filter: blur(5px);
  background-color: $semiTransparentDarkBlue;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_card {
  background: $cardBG;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  width: 500px;
  height: 400px;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal_card__title {
  @extend .large_btn__title__center;
  @extend .border_bottom;
  @extend .text__wrapper;
  @extend .display_block;
  font-size: $mainTextSize;
}

.modal_confirmation {
  min-width: 300px;
  max-width: 35%;
  width: unset;
  height: fit-content;
}

.modal_send_email {
  @extend .modal_confirmation;
  min-width: 460px;
}

.container__main_office_info {
  @extend .container__left_side;
  @extend .full_width;
  border-right: 0;
  padding-top: 0;
  box-sizing: border-box;
  padding-right: 15px;
  & .underlined_title__block {
    margin-right: 30px;
  }
}

.container__main_zones_info {
  @extend .container__left_side;
  @extend .full_width;
  display: flex;
  border-right: 0;
  padding-top: 0;
  margin-right: 15px;
  padding-right: 15px;
  & .card_list form {
    padding: 1px;
  }
  & .card_list {
    padding-right: 2px;
    margin-right: 15px;
  }
  & .container__add_zone {
    margin-right: 15px;
    & .card_list {
      margin-right: 0;
    }
  }
}

.container__main_floors_info {
  @extend .container__left_side;
  @extend .full_width;
  display: flex;
  border-right: 0;
  padding-top: 0;
  margin-right: 15px;
  padding-right: 15px;
  & .card_list form {
    padding: 1px;
  }
  & .card_list {
    padding-right: 2px;
    margin-right: 15px;
  }
  & .container__add_floor {
    margin-right: 15px;
    & .card_list {
      margin-right: 0;
    }
  }
}

.container__screen_info {
  @extend .container__left_side;
  @extend .full_width;
  border-right: 0;
  padding-top: 0;
}

.modal__table_programming {
  @extend .flex_container;
  justify-content: space-between;
  align-items: center;
  margin: 30px 20px 0;
}

.flex_container__icon_text {
  @extend .flex_container;
  justify-content: center;
  align-items: center;
}

.card_list_item__code {
  @extend .flex_container__center;
  @extend .flex_container__flex_1;
  @extend .full_height;
}

.tags_block_container {
  @extend .flex_container;
  @extend .flex_container__center;
}

.flex_container__new_booking {
  @extend .flex_container__column;
  @extend .flex_container__flex_1;
  & form {
    flex:1;
  }
}

.container__booking__floor_field {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  width: 20%;
  max-width: 20%;
}

.container__booking__room_field {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 45%;
  max-width: 45%;
}

.container__booking__table_field {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 35%;
  max-width: 35%;
}

.container__link_block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 49px;
  width: 49px;
  background-color: $topCardBG;
  border-top-right-radius: 15px;
  cursor: pointer;
}

.ScrollbarsCustom {
  & form {
    flex: 1;
  }
  flex: 1;
}

.container__booking_type {
  .fade_block-enter-active > &,
  .fade_block-enter-done > &,
  .fade_block-appear > &,
  .fade_block-appear-active > &,
  .fade_block-appear-done > & {
    flex-direction: row !important;
  }
}

.radio_group__horizontal {
  & .ant-radio-group {
    flex-direction: row !important;
    align-items: baseline;
    height: 32px;
  }
  & .ant-radio-wrapper {
    margin-right: 10px;
  }
  & .ant-radio-wrapper:last-child,
  & .ant-radio-wrapper:first-child{
    margin: 5px 0 8px;
  }
}

.card__small__chart_block {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: calc(100% - 61px);
  height: calc(100% - 61px);
  min-height: 1px;
}

.card__small__chart_wrapper {
  flex: 2;
  max-width: calc(100% - 44px);
}

.display_block {
  display: block;
  text-align: center;
}

.button_container__edit_card {
  @extend .button_container;
  margin-right: 17px;
}

.button_container__add_presenter {
  @extend .button_container__content_right;
  margin: 0 17px 40px 0;
}

.user_group_form__radio_group {
  & .ant-radio-wrapper {
    margin: 4px 0;
    padding-left: 6px;
  }
}

.max_min_height__setup {
  max-height: 100%;
  min-height: 1px;
}

.top_right__icon_container {
  position: absolute;
  top: 22px;
  right: 30px;
}

.container__users_group_search {
  margin: 8.5px 0;
}

.container__slide_input {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  padding-left: 2px;
  //padding-bottom: 6px;
  & .ant-row {
    box-sizing: border-box;
    width: 100%;
    //padding: 2px;
    height: 50px;
    align-self: flex-end;
    //margin-bottom: 4px;
  }
  & .ant-col {
    width: 100%;
  }
  & .ant-form-item-control {
    margin-top: 0;
  }
  & .fade_block-enter .ant-row {
    width: 100%;
  }
  & .fade_block-enter-active .ant-row {
    width: 100%;
  }
}

.container__slide_input__wrapper {
  display: flex;
  height: fit-content;
  flex: 1;
  align-items: center;
  padding: 0 4px 0 0;
}

.container__booking__user_info {
  @extend .flex_container__column;
  @extend .full_height;
  padding-right: 17px;
  flex: 1;
}

.margin_right__17 {
  margin-right: 17px;
  overflow: auto;
}

.container__add_floor {
  padding-top: 20px;
  border-top: .5px solid $divider;
  transition: border .1s ease;
  & form {
    padding: 2px;
  }
  &__active {
    @extend .container__add_floor;
    border-top: .5px solid transparent;
    padding-top: 0;
  }
}

.container__add_zone {
  padding-top: 20px;
  border-top: .5px solid $divider;
  transition: border .1s ease;
  & form {
    padding: 2px;
  }
}

.container__add_zone__active {
  @extend .container__add_zone;
  border-top: .5px solid transparent;
  padding-top: 0;
}

.container__one_line_info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 20px 0;
  border-bottom: .5px solid $divider;
  &__group {
    display: flex;
    flex-direction: row;
    & div {
      flex: 1;
    }
    & .container__one_line_info {
      &:first-child {
        flex: 1.5;
        overflow: auto;
        border-right: .5px solid $divider;
        padding-right: 20px;
      }
      &:last-child {
        padding-left: 30px;
      }
    }
  }
}

.one_line_info__label {
  color: $labelText;
  font-size: $secondaryTextSize;
  margin-right: 20px;
  display: block;
  white-space: nowrap;
}

.one_line_info__text {
  @extend .text__wrapper;
}

.code__block {
  flex: 1;
  background-color: $darkBlueBG;
  border-radius: 8px;
  padding: 14px 12px;
  margin-bottom: 20px;
}
