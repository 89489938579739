@import "constants";

.card_list {
  padding-right: 17px;
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  user-select: none;
  &__zones {
    @extend .card_list;
    padding-bottom: 20px;
    & :last-child form .button_container__save_zone {
      margin-bottom: 0;
    }
  }
  &__floors {
    @extend .card_list;
    padding-bottom: 20px;
    & :last-child form .button_container__save_floor {
      margin-bottom: 0;
    }
  }
}

.card_list__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 25px;
  min-height: 25px;
  font-size: $secondaryTextSize;
  margin: 0 12px 4px 2px;
  user-select: none;
  & div {
    display: inline-block;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:first-child {
      margin-left: 27px;
    }
    &:last-child {
      margin-right: 27px;
    }
  }
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    border-radius: 2px;
    height: 1px;
    min-height: 1px;
    bottom: 0;
    background-color: $blue;
  }
}

.card_list__item {
  height: 71px;
  min-height: 71px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: $topCardBG;
  padding: 15px 25px;
  box-sizing: border-box;
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
  border: .5px solid transparent;
  position: relative;
  transition: box-shadow .3s ease-out, border-color .8s ease, background-color .2s ease, height 0.3s ease;
  &:first-child {
    margin-top: 2px;
  }
  & :nth-last-child(3) {
    border: 0;
  }
  &:hover {
    box-shadow: 0 0 6px $blue, 0 4px 8px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
  &:active {
    transition: box-shadow .2s ease-out;
    box-shadow: 0 0 4px $blue, 0 0 8px $semiTransparentBlack;
    background-color: $topCardBGActive;
    position: relative;
    z-index: 2;
  }
}

.card_list__item__user__hover {
  @extend .card_list__item__small;
  @extend .cursor_default;
  & .card_list__item_description {
    border-right: 0px;
  }
  -webkit-animation: fade .4s;
  animation: fade .4s;
  animation-name: fade;
  transition: box-shadow .2s ease-out, background-color .2s ease;
  &:active {
    background-color: #181e28;
    transition: box-shadow .2s ease-out, background-color .2s ease;
  }
}

.card_list__item__user__no_hover {
  @extend .card_list__item__user__hover;
  @extend .card_list__item__no_hover;
  &:active {
    background-color: $topCardBG;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}

.card_list__item__small {
  @extend .card_list__item;
  height: 50px;
  min-height: 50px;
  user-select: none;
  overflow: hidden;
  & .card_list__item__group_title {
    line-height: 1;
  }
}

.card_list__item__zone {
  padding: 15px 15px;
  & .card_list__item_id {
    min-width: 25px;
  }
}

.card_list__item__zone_name {
  @extend .text__wrapper;
  font-size: $secondaryTextSize;
  color: $mainText;
  border: 0;
  flex: 3;
  height: 100%;
  line-height: 1.3;
}

.card_list__item__floor_name {
  @extend .text__wrapper;
  font-size: $secondaryTextSize;
  color: $mainText;
  border: 0;
  flex: 3;
  height: 100%;
  line-height: 1.3;
}

.card_list__item__active {
  @extend .card_list__item;
  transition: box-shadow .2s ease;
  box-shadow: 0 0 4px $blue;
  position: relative;
  z-index: 2;
}

.card_list__avatar {
  height: 40px;
  width: 40px;
  border-radius: 25px;
  background-color: $divider;
  background-size: cover;
  background-repeat: no-repeat ;
  background-position: center ;
}

.card_list__item_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-right: 2%;
}

.card_list__item_name {
  @extend .main_text;
  @extend .text__wrapper;
  flex: 3;
  height: 100%;
  line-height: 41px;
  padding-right: 2%;
  color: $subtitle;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_user_name {
  @extend .main_text;
  @extend .text__wrapper;
  flex: 3;
  height: 100%;
  line-height: 41px;
  padding-right: 2%;
  color: $subtitle;
  min-width: 40%;
}

.card_list__item_screen_name {
  @extend .card_list__item_name;
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}

.card_list__item__group_title {
  @extend .main_text;
  @extend .text__wrapper;
  flex: 2;
  height: 100%;
  line-height: 41px;
  padding-right: 2%;
  color: $subtitle;
  border-right: 0.5px solid #2B3647 !important;
}

.card_list__item_access {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 1.3;
  margin: 0 2%;
  padding-right: 2%;
  text-align: center;
}

.card_list__item__users_count {
  @extend .text__wrapper;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1.3;
  margin: 0 2%;
  padding-right: 2%;
  text-align: center;
  & .secondary_text__white {
    margin-left: 6px;
  }
}

.card_list__item_description {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 3;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: row;
  padding-right: 2%;
  margin-left: 2%;
  border-right: .5px solid $cardListDivider;
  & .secondary_text {
    @extend .text__wrapper__two_lines;
    display: block;
  }
}

.card_list__item__user_description {
  @extend .card_list__item_description;
  min-width: 30%;
  margin-left: 0;
  padding-left: 2%;
  border-right: none;
  border-left: .5px solid $cardListDivider !important;
}

.card_list__item__presenter_description {
  @extend .card_list__item_description;
  border-right: none;
  display: block;
  height: auto;
}

.card_list__item_table_type {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 3;
  height: 100%;
  padding-right: 2%;
  display: block;
  margin-left: 1.2%;
  border-right: .5px solid $cardListDivider;
  & .secondary_text {
    @extend .text__wrapper__two_lines;
    display: block;
  }
}

.card_list__item_address {
  @extend .secondary_text;
  flex: 2.5;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  & .secondary_text {
    @extend .text__wrapper__two_lines;
  }
}

.card_list__item_small_description {
  @extend .main_text__white;
  flex: .7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 2%;
  margin-left: 2%;
  border-right: .5px solid $cardListDivider;

}

.card_list__item_tag_title {
  @extend .text__wrapper;
  height: 100%;
  line-height: 41px;
  color: $subtitle;
  font-size: $mainTextSize;
  text-align: left;
  padding-right: 12px;
  box-sizing: border-box;
}

.card_list__item_office_name {
  @extend .text__wrapper;
  height: 100%;
  line-height: 41px;
  color: $subtitle;
  font-size: $mainTextSize;
  flex: 3;
  text-align: left;
}

.card_list__item_colon_block {
  @extend .text__wrapper;
  font-size: $secondaryTextSize;
  display: flex;
  height: 100%;
  width: 17%;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  padding-right: 4%;
  text-align: left;
  border-right: .5px solid $cardListDivider;
  &:last-child {
    border: 0;
  }
  & div {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    max-width: 50%;
  }
  & .secondary_text {
    line-height: 20px;
    margin-left: 14px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.card_list__item_colon_block__left_border {
  @extend .card_list__item_colon_block;
  border-right: 0;
  border-left: .5px solid $cardListDivider !important;
  padding-left: 15px;
  margin-left: 2%;
  padding-right: 2%;
  & .secondary_text__white {
    @extend .text__wrapper;
  }
}

.card_list__item_colon_block__table {
  @extend .card_list__item_colon_block;
  flex: 5;
  align-items: flex-start;
  & .secondary_text__white {
    @extend .text__wrapper;
  }
}

.card_list__item__rating {
  flex: 1;
  display: flex;
  color: $mainText;
  align-items: center;
  justify-content: flex-start;
  margin: 0 2% 0 10px;
}

.card_list__item__colon {
  flex: 5;
  display: flex;
  height: 100%;
}


.card_list__item_id {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: .3;
  max-width: 15%;
  min-width: 32px;
  display: block;
  align-items: center;
  justify-content: flex-start;
  .card_list__item_marker + & {
    flex: .1;
  }
}

.card_list__item_marker {
  flex: .08;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 2% 0 5px;
  max-width: 6px;
}

.card_list__item_title {
  @extend .text__wrapper;
  @extend .main_text;
  color: $subtitle;
  height: 100%;
  line-height: 41px;
  flex: 8;
  padding-right: 2%;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_place {
  @extend .text__wrapper;
  @extend .secondary_text;
  flex: 2.5;
  height: 100%;
  line-height: 41px;
  margin-left: 2%;
  padding-right: 2%;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_is_over {
  @extend .card_list__item_place;
  flex: 1;
  padding-left: 4%;
  border-right: 0;
  border-left: .5px solid $cardListDivider;
}

.card_list__item_time {
  @extend .text__wrapper;
  @extend .secondary_text;
  flex: 1.2;
  justify-content: center;
  margin: 0 2%;
}

.card_list__item_date {
  @extend .text__wrapper;
  @extend .secondary_text;
  flex: .8;
  justify-content: center;
  margin: 0 2%;
}

.card_list__item_voting_date {
  @extend .card_list__item_date;
  flex: 1;
}

.card_list__item_duration {
  display: flex;
  flex-direction: row;
  min-width: 145px;
  flex: 2.5;
  border-right: .5px solid $cardListDivider;
  margin: 0 2%;
  padding-right: 3%;
  justify-content: center;
  & .main_text {
    @extend .text__wrapper;
    display: block;
  }
  &__table_screen {
    display: flex;
    flex-direction: row;
    min-width: 145px;
    flex: 1.7;
    width: 30%;
    max-width: 30%;
    padding-left: 2%;
    border-right: .5px solid $cardListDivider !important;
    & .flex_container__column {
      align-items: flex-end;
    }
  }
}

.card_list__item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.card_list_item__reserve {
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: $topCardBG;
  padding: 12px 20px;
  box-sizing: border-box;
  margin-bottom: 3px;
  cursor: pointer;
  transition: box-shadow .8s ease-out;
  .container__floor & {
    margin-bottom: 9px;
  }
  &:first-child {
    margin-top: 2px;
  }
  &:hover {
    transition: box-shadow .3s ease-out;
    box-shadow: 0 0 6px $blue, 0 4px 10px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
  &:active {
    transition: box-shadow .2s ease-out;
    box-shadow: 0 0 4px $blue, 0 0 8px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
  &:last-child {
    margin-bottom: 2px;
  }
  & h1 {
    @extend .text__wrapper;
    display: flex;
    justify-content: center;
    flex: 4;
  }
}

#rooms-list.room_card__small {
  user-select: none;
  & h1 {
    @extend .text__wrapper;
    font-size: 20px;
    text-align: left;
    line-height: 20px;
  }
  & .card_list__side_info {
    display: block;
    flex: 1.4;
    overflow: visible;
    line-height: 1.5;
    max-width: 30%;
    & div {
      @extend .text__wrapper;
      margin: 0 auto;
      text-align: center;
      height: min-content;
      &:first-child {
        margin-bottom: 4px;
      }
      &:last-child {
        margin-top: 7px;
      }
    }
  }
  & .container__justify__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.room_card__main_info {
  display: flex;
  flex-direction: column;
  flex: 6;
  overflow: auto;
  & span {
    @extend .text__wrapper;
    margin-top: 6px;
    line-height: 1;
  }
}

.room_card__add_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_list__item__room_zone {
  @extend .card_list__item_description;
  display: flex;
  margin-left: 0;
  padding-left: 2%;
  border-right: .5px solid $cardListDivider;
  & span {
    @extend .text__wrapper;
  }
}


.card_list_item__reserve__active {
  @extend .card_list_item__reserve;
  transition: box-shadow .2s ease;
  box-shadow: 0 0 4px $blue;
  position: relative;
  z-index: 2;
}

.card_list__item__presenter_status_block {
  display: flex;
  flex: 1;
  min-width: 130px;
  justify-content: flex-end;
  align-items: center;
}

.card_list__item__presenter_stop_btn {
  display: flex;
  flex: 1;
  margin-right: 14px !important;
  justify-content: center;
}

.card_list__item__presenter_status {
  padding: 0 13px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  color: $white;
  width: min-content;
  background-color: $lightRed;
  white-space: nowrap;
  cursor: pointer;
  transition: box-shadow .2s ease;
  &:hover {
    box-shadow: 0 0 6px rgba(218, 82, 82, .5);
  }
  &:active {
    box-shadow: 0 0 4px rgba(218, 82, 82, .5);
  }
}

.card_list__item__presenter_status__active {
  @extend .card_list__item__presenter_status;
  background-color: $green;
  &:hover {
    box-shadow: 0 0 6px rgba(49, 203, 129, .5);
  }
  &:active {
    box-shadow: 0 0 4px rgba(49, 203, 129, .5);
  }
}

.card_list__item__presenter_status__waiting {
  @extend .card_list__item__presenter_status;
  background-color: $secondaryText;
  &:hover {
    box-shadow: 0 0 6px rgba(122, 125, 130, .5);
  }
  &:active {
    box-shadow: 0 0 4px rgba(122, 125, 130, .5);
  }
}

.card_list__item__no_hover {
  box-shadow: none !important;
  cursor: default;
  background-color: $topCardBG !important;
  & .card_list__item_id {
    margin-right: 2%;
  }
}

.card_list__item_pop_up {
  position: absolute;
  background-color: $topCardBG;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  right: 24px;
  top: 50px;
  z-index: 4;
  padding: 12px 20px;
  ul {
    @extend .secondary_text__white;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      height: 34px;
      align-items: center;
      transition: color .2s ease;
      border: 0;
      a {
        color: white;
        &:hover {
          color: $blueLink;
        }
      }
      div {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-top: 0 !important;
      }
      &:hover {
        color: $blueLink;
      }
      &:last-child {
        border-top: 1px solid $darkDivider
      }
    }
  }
}

// <-- DoubleList -->

.card_list__small {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  width: 100%;
  padding: 0 17px 0 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.card_list__small__wrapper {
  padding: 0 10px 0 2px;
}

.card_list__small_container__title {
  @extend .secondary_text_subtitle;
  @extend .text__uppercase;
  padding: 0 0 4px 10px;
  user-select: none;
  text-transform: uppercase;
}

.list__offices {
  & span {
    margin-right: 10px;
  }
  & .icon__more {
    margin: 0;
  }
}
