@import 'index';

.icon {
  background-size: cover;
  display: inline-block;
  cursor: pointer;
}

.icon__wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  box-sizing: border-box;
  max-width: 20px;
  min-width: 20px !important;
  padding: 6px 0;
  cursor: pointer;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
}

.icon__wrapper__hover_enabled {
  @extend .icon__wrapper;
  &:hover {
    &::after {
      opacity: 1;
      content: '';
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      width: 24px;
      height: 24px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
    }
  }
}

.icon__wrapper__full_height {
  @extend .icon__wrapper;
  height: 100%;
}

.expand_arrow {
  background: url('../assets/images/icons/expand_arrow.svg');
  background-size: cover;
  width: 10px;
  height: 6px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.icon__add__in_circle {
  @extend .icon;
  background: url('../assets/images/icons/add_rounded.svg');
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.icon__trash {
  @extend .icon;
  background: url('../assets/images/icons/icon_trash.svg');
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon__add_rounded__green {
  @extend .icon;
  background: url('../assets/images/icons/icon_add_green.svg');
  background-size: contain;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: 0 0 6px $greenMarker;
  }
}

.icon__close {
  @extend .icon;
  box-sizing: border-box;
  background: url('../assets/images/icons/close_icon.svg');
  margin-left: 7px;
  width: 14px;
  height: 14px;
}

.icon__delete {
  @extend .icon;
  background: url('../assets/images/icons/icon_delete_red.svg');
  height: 8px;
  width: 8px;
  .notification__subtitle_block & {
    padding-right: 20px;
    margin-right: 5px;
    margin-bottom: 1px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon__x {
  @extend .icon;
  background: url('../assets/images/icons/x.svg');
  height: 20px;
  width: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
  background-size: cover;
}

.icon__add_tag_btn {
  @extend .icon;
  width: 9px;
  height: 9px;
  background: url('../assets/images/icons/plus.svg') no-repeat;
}

.icon__close__invert {
  @extend .icon;
  height: 12px;
  width: 12px;
  background: url('../assets/images/icons/closeIcon_invert.svg');
  position: absolute;
  top: 18px;
  right: 12px;
}

.icon__calendar {
  @extend .icon;
  height: 12px;
  width: 12px;
  background: url('../assets/images/icons/calendar.svg') center no-repeat;
}

.icon__search {
  @extend .icon;
  min-height: 12px;
  min-width: 12px;
  background: url('../assets/images/icons/icon_search.svg') no-repeat;
  background-size: cover;
}

.icon__groups {
  background: url('../assets/images/icons/icon_groups.svg') no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.icon__clock {
  background: url('../assets/images/icons/icon_clock.svg') no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
  margin-right: 12px;
}

.icon__notification {
  background: url('../assets/images/icons/icon_push-notification.svg') no-repeat;
  background-size: cover;
  width: 18px;
  height: 17px;
  margin-right: 12px;
}

.icon__edit {
  cursor: pointer;
  background-size: cover;
  background: url('../assets/images/icons/icon_edit.svg') no-repeat;
  height: 11px;
  width: 12px;
}

.icon__booking {
  cursor: pointer;
  background-image: url('../assets/images/icons/icon_booking.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: initial !important;
  background-position-y: bottom !important;
  height: 16px;
  width: 16px;
}

.icon__chart {
  background-size: cover !important;
  margin-right: 0;
  width: 16px;
  height: 16px;
  background: url('../assets/images/icons/icon_chart.svg') no-repeat;
}

.icon__map_marker {
  background-size: contain !important;
  margin-right: 12px;
  width: 14px;
  height: 16px;
  background: url('../assets/images/icons/icon_map_marker.svg') no-repeat;
}

.icon__expand {
  background: url('../assets/images/icons/icon_expand.svg') no-repeat;
  height: 6px;
  width: 10px;
  @extend .icon;
  transition: transform 0.4s ease-out;
  transform: rotate(0deg);
}

.icon__user {
  background: url('../assets/images/icons/icon_user.svg') no-repeat;
  width: 16px;
  height: 18px;
  margin-right: 12px;
  @extend .icon;
}

.icon__briefcase {
  background: url('../assets/images/icons/icon_briefcase.svg');
  background-size: cover;
  width: 17px;
  height: 16px;
  margin-right: 12px;
}

.icon__screen {
  background: url('../assets/images/icons/icon_monitor.svg');
  background-size: cover;
  width: 17px;
  height: 16px;
  margin-right: 12px;
}

.icon__plus {
  @extend .icon;
  height: 10px;
  width: 10px;
  margin-right: 25px;
  background-size: cover;
  background: url('../assets/images/icons/icon_plus.svg') no-repeat;
}

.icon__plus__green {
  @extend .icon;
  height: 10px;
  width: 10px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_green_plus.svg');
}

.icon__logout {
  @extend .icon;
  height: 13px;
  width: 14px;
  background-size: cover;
  margin-right: 6px;
  margin-top: 1px;
  background-image: url('../assets/images/icons/icon_logout.svg');
}

.icon__star {
  @extend .icon;
  height: 10px;
  width: 10px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_star.svg');
}

.icon__big_star {
  @extend .icon;
  height: 13px;
  width: 13px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_star.svg');
}

.icon_delete__gray {
  @extend .icon;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_delete_gray.svg');
  width: 10px;
  height: 10px;
  position: relative;
  z-index: 2;
}

.ant-input-password {
  & .ant-input-suffix {
    width: auto;
  }
}

.ant-input-password-icon {
  color: $mainText;
  &:hover {
    color: $white;
  }
}

.icon__location {
  background-size: cover;
  background: url('../assets/images/icons/icon_location.svg') no-repeat;
  height: 13px;
  min-height: 13px;
  width: 11px;
  min-width: 11px;
  .card_list__item_address & {
    margin-right: 10px;
  }
}

.arrow_table {
  margin: -10px 10px 0 0;
  height: 40px;
  min-width: 25px;
  background-image: url('../assets/images/icons/arrow_table.svg');
  background-size: cover;
}

.checked_marker {
  height: 20px;
  width: 20px;
  margin-left: 4px;
  border: 0.5px solid $cardListDivider;
  border-radius: 10px;
}

.icon__bell {
  cursor: pointer;
  height: 20px;
  & svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: $secondaryText;
      transition: stroke 0.3s ease;
    }
    &:hover path {
      stroke: $blue;
      transition: stroke 0.2s ease;
    }
  }
}

.icon__bell__active {
  @extend .icon__bell;
  & svg {
    & path {
      stroke: $blue;
      transition: stroke 0.2s ease;
    }
  }
}

.icon__print {
  cursor: pointer;
  & svg {
    width: 11px;
    height: 11px;
    & path {
      fill: $blue;
    }
  }
}

.icon__email {
  cursor: pointer;
  & svg {
    width: 11px;
    height: 11px;
    & path {
      fill: $blue;
    }
  }
}

.icon__office_map {
  cursor: pointer;
  & svg {
    width: 18px;
    height: 13px;
    & path {
      stroke: $green;
    }
  }
}

.icon__add_white {
  height: 16px;
  cursor: grab;
  & svg {
    width: 16px;
    height: 16px;
    & path {
      stroke: $white;
    }
  }
}

// .icon__bucket-on-map {
//   cursor: none;
//   & svg {
//     & path {
//       stroke: $white;
//     }
//   }
// }

// .icon__lid-bucket-on-map {
//   cursor: none;
//   & svg {
//     & path {
//       stroke: $white;
//     }
//   }
// }

.icon__add_inactive {
  height: 16px;
  cursor: pointer;
  & svg {
    width: 16px;
    height: 16px;
    & path {
      fill: $lightBtnBG;
    }
  }
}

.icon__edit__svg {
  cursor: pointer;
  & svg {
    width: 18px;
    & path {
      fill: $blue;
    }
  }
}

.icon__expand_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  height: 20px;
  & svg {
    width: 16px;
    height: 6px;
    & path {
      stroke: $white;
    }
  }
}

.icon__more {
  @extend .icon;
  @extend .icon__wrapper__hover_enabled;
  height: 18px;
  padding: 0;
  background: url('../assets/images/icons/icon_more.svg') center no-repeat;
  background-size: contain;
}

.icon__active {
  &::after {
    opacity: 1;
    content: '';
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
}

.GPN-logo {
  width: 70%;
  height: 100%;
  background: url('../assets/images/GPN-logo.svg') no-repeat;
  background-size: contain;
  align-self: center;
  background-position-y: center;
}

.icon__info_svg {
  height: 11px;
  & :first-child,
  & path {
    fill: $labelText;
  }
  & :last-child {
    stroke: $labelText;
  }
  &__green {
    & :first-child,
    & path {
      fill: $greenMarker;
    }
    & :last-child {
      stroke: $greenMarker;
    }
  }
  &__red {
    & :first-child,
    & path {
      fill: $red;
    }
    & :last-child {
      stroke: $red;
    }
  }
  &__orange {
    & :first-child,
    & path {
      fill: $yellow;
    }
    & :last-child {
      stroke: $yellow;
    }
  }
}

.icon__download_2 {
  width: 18px;
  height: 18px;
  background: url('../assets/images/icons/icon_download_2.svg');
  background-size: cover;
  margin-right: 12px;
  & svg {
    width: 18px;
    height: 18px;
    fill: $green;
  }
}

.icon__save_blue {
  & path {
    fill: $blue;
  }
}

.icon__map__svg {
  cursor: pointer;
  svg {
    fill: #31cb81;
  }
}

.icon__delete__svg {
  cursor: pointer;
  svg {
    fill: #cd4141;
  }
}

.icon__edit-outline__svg {
  cursor: pointer;
}

.icon__save__svg {
  cursor: pointer;
  svg {
    fill: #0079c1;
  }
}

.icon_preview {
  border-radius: 5px;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
