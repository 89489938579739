.notifications {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    & > div {
      padding-right: 30px;
    }
    & > div:last-child {
      padding-right: 0;
    }
    h3 {
      color: white;
      font-family: 'DIN Pro';
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    padding-left: 2px;
    & > span {
      text-align: right;
      margin-top: -7px;
      color: $secondaryText;
      font-size: $secondaryTextSize;
    }
    .validation_block > .validation_block {
      margin: 0;
    }
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    .checkbox-wrapper {
      margin: 13px 0 0 28px;
    }
    .ReactVirtualized__Grid__innerScrollContainer {
      @extend .notifications__list-wrapper;
      padding: 0 !important;
      outline: none !important;
    }
    .search_field__large {
      position: relative;
    }
    .pop-up {
      background-color: $darkBlueBG;
      border-radius: 10px;
      position: absolute;
      top: 40px;
      z-index: 1;
      width: 100%;
      height: min-content;
      max-height: 400px;
      color: white;
      text-align: left;
      align-items: flex-start;
      padding: 20px 25px;
      transition: transform .15s ease;
      transform: scaleY(1);
      transform-origin: top;
      overflow: hidden auto;
      .ant-checkbox-wrapper {
        margin-right: 20px;
      }
      &_hidden {
        @extend .pop-up;
        transform-origin: top;
        transform: scaleY(0); 
      }
      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        user-select: none;
        border-top: 1px solid rgba(255,255,255, 0.1);
        &:first-child {
          padding-top: 0;
          border-top: 0 !important;
        }
        &:last-child {
          padding-bottom: 0;
          user-select: none;
        }
      }
    }
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    padding: 0 10px 0 2px;
    .groups-list {
      &__item-title {
        display: inline-block;
        flex: 1;
      }
      &__item-count {
        font-size: $smallTextSize;
        color: $labelText;
        &::before {
          content: 'Всего:';
          margin-right: 4px;
        }
      }
    }

    .ant-checkbox-wrapper {
      display: inline-block;
      margin-right: 20px;
      .ant-checkbox-inner {
        margin-top: -3px;
      }
      .ant-checkbox-checked::after {
        border: 0;
      }
    }

    .users-list {
      &__item-title {
        display: inline-block;
        flex: 1;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 0;
    margin-top: 20px;
  }

  &__modal {
    max-height: 95%;
    height: 95%;
    h3 {
      font-size: $mainTextSize;
      color: white;
      font-family: 'DIN Pro';
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 10px;
      &:nth-child(2) {
        margin-top: 10px;
      }
    }
    .large_btn__title__center {
      text-transform: uppercase;
    }
    .labeled_field__block__bordered {
      border: 0;
    }
    .recipients-list__bullet {
      flex: 1;
      margin-right: 15px;
    }
    .recipients-list {
      padding: 2px 6px 2px 2px;
      &__subtitle {
        @extend .secondary_text__inactive;
        margin-bottom: 10px;
      }
      .card_list__item__small {
        &:first-child {
          margin-top: 0;
        }
        span:nth-child(2) {
          @extend .text__wrapper;
          flex: 8;
        }
      }
    }
  }
}

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  outline: none !important;
  overflow: visible !important;
  // &::-webkit-scrollbar {
  //   width: 4px;
  // }
  // &::-webkit-scrollbar-track {
  //   border-radius: 2px;
  //   width: 4px;
  // }
}