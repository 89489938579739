@import '../../../styles/constants.scss';

.groups-screen {
  &__office-card {
    background-color: $topCardBG;
    padding: 12px 25px;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    .office-card {
      &__zones-list {
        display: flex;
        flex-direction: column;
      }
      &__zone {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        &:last-child {
          padding-bottom: 0;
        }
        &-id {
          flex: 1;
          max-width: 15%;
          min-width: 32px;
          align-items: center;
          justify-content: flex-start;
          display: block;
          color: $secondaryText;
          font-size: $secondaryTextSize;
        }
        &-title {
          flex: 24;
          display: block;
          color: $secondaryText;
          font-size: $secondaryTextSize;
          color: $white;
          font-size: $secondaryTextSize;
        }
      }
      &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        border-radius: 4px;
      }
      &__office {
        &-description {
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1;
          display: block;
          color: $mainText;
          font-size: $secondaryTextSize;
          margin-left: 20px;
          padding-right: 20px;
          display: flex;
          flex-direction: row;
          & > div {
            margin-right: 10px;
          }
        }
        &-title {
          display: block;
          color: $white;
          font-size: $mainTextSize;
          flex: 1;
          font-family: 'DIN Pro';
          border-right: 1px solid $darkDivider;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
