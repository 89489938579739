.floors-list {
  padding: 0 10px 0 2px;
  &__item {
    &-title {
      flex: 1 1 0%;
      color: $subtitle;
      height: 100%;
      line-height: 41px;
      font-size: $mainTextSize;
      text-align: left;
      padding-right: 12px;
      box-sizing: border-box;
    }
    &-action {
      display: flex;
      position: relative;
      &:not(:last-child) {
        margin-right: 14px;
      }
      &:hover {
        &:after {
          opacity: 1;
          content: '';
          position: absolute;
          top: calc(50% - 13px);
          left: calc(50% - 14px);
          width: 27px;
          height: 27px;
          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 4px;
        }
      }
    }
    &-input {
      width: 95%;
      margin-left: 2px;
    }
    .secondary_text__inactive {
      flex: 1;
    }
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: min-content;
    &:hover {
      &::after {
        opacity: 1;
        content: '';
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 14px);
        width: 27px;
        height: 27px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
      }
    }
    &:not(:last-child) {
      margin-right: 14px;
    }
  }
}
