@import '../constants';

%card-list__item_16 {
  @extend .text__wrapper;
  height: 100%;
  line-height: 41px;
  color: $subtitle;
  font-size: $mainTextSize;
  text-align: left;
  margin-right: 20px;
  box-sizing: border-box;
}

%card-list__item_14 {
  @extend .text__wrapper;
  height: 100%;
  line-height: 41px;
  color: $subtitle;
  font-size: $secondaryTextSize;
  text-align: left;
  margin-right: 20px;
  box-sizing: border-box;
}

.office_main_info__card {
  display: flex;
  flex-direction: column;
  background: #191f28;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  height: 100%;
  max-height: 100%;
  overflow: visible !important;
  position: relative;
  padding: 30px 50px 30px 60px;
  min-width: 550px;
  width: 70%;
  max-width: 900px;
  & table {
    display: flex;
    flex-direction: column;
    width: 100%;
    & tr {
      width: 100%;
    }
  }
}

.side_panel {
  // position: absolute;
  display: flex;
  flex-direction: column;
  width: 220px;
  min-width: 220px;
  margin-right: 20px;
  // left: -250px;
  // top: 0;
  &_item {
    height: 48px;
    border-radius: 30px;
    background-color: $divider;
    width: 100%;
    margin-bottom: 10px;
    line-height: 48px;
    padding: 0 20px;
    cursor: pointer;
    user-select: none;
    &__inactive {
      @extend .side_panel_item;
      color: $labelText;
      background-color: transparent;
      border: 1px solid $cardListDivider;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__back_btn {
    height: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    transition: color 0.2s ease;
    &::before {
      content: '';
      width: 10px;
      height: 14px;
      margin-right: 10px;
      display: inline-block;
      background: url('../../assets/images/icons/icon_go_back.svg');
    }
    &:hover,
    &:active {
      color: $blueLink;
    }
  }
}

.rooms-list,
.tags-list {
  padding: 0 10px 0 2px;
  &__icon_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: min-content;
    &:hover {
      &::after {
        opacity: 1;
        content: '';
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 14px);
        width: 27px;
        height: 27px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
      }
    }
    &:not(:last-child) {
      margin-right: 14px;
    }
  }
}

.office-details-screen {
  &__summary {
    padding: 0.5rem 0 1rem 27px;
    display: flex;
    align-items: center;
    &-info {
      display: inline-block;
      min-width: 220px;
      line-height: 1;
    }

    .icon__print {
      display: inline-flex;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .icon__print,
    .icon__trash {
      position: relative;
      margin-left: 1rem;
      &:hover:after {
        opacity: 1;
        content: '';
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 13px);
        width: 26px;
        height: 26px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
      }
    }
  }
  .card_list__header div {
    margin-right: 15px;
    &:nth-child(2) {
      margin-right: 18px;
    }
  }
}

.rooms-list {
  &__room-type,
  &__room-zone,
  &__room-title {
    @extend %card-list__item_16;
  }
  &__room-floor,
  &__room-book {
    @extend %card-list__item_14;
  }
  &__room-number {
    @extend %card-list__item_14;
    color: $labelText;
  }
  &__checkbox {
    height: 24px;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: min-content;
    &:hover {
      &::after {
        opacity: 1;
        content: '';
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 14px);
        width: 27px;
        height: 27px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
      }
    }
    &:not(:last-child) {
      margin-right: 14px;
    }
  }
  .card_list__item > div {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-right: 1px solid $cardListDivider;
    }
  }
  .list-more-menu li {
    border-right: 0;
  }
}

.color-pick-modal {
  &__marker-preview-container {
    height: 207px;
    margin: 30px 0 50px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 160px;
      top: 0;
      left: calc(50% - 80px);
      height: 207px;
      position: absolute;
      background: url('../../assets/images/marker-preview-wrapper.svg') center
        no-repeat;
      background-size: cover;
    }
  }
  &__colors-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &__color-preview {
    width: 100px;
    height: 37px;
    border-radius: 5px;
    margin: 0 15px 15px 0;
    cursor: pointer;
    position: relative;
    transition: box-shadow 0.2s ease;
    &:hover {
      box-shadow: 0 0 0 2px white;
    }
    &:active {
      box-shadow: 0 0 0 1px white;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    &_checked {
      @extend .color-pick-modal__color-preview;
      box-shadow: 0 0 0 3px white;
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 14px;
        height: 10px;
        top: calc(50% - 5px);
        left: calc(50% - 7px);
        background: url('../../assets/images/icons/icon_check.svg') center
          no-repeat;
        background-size: cover;
      }
    }
  }
}

.marker-preview {
  margin: 10px auto 0;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  background-color: black;
  z-index: 1;
  position: relative;
  transition: background-color 0.2s ease;
  img {
    padding: 30px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
}
