.filter-input {
  &-wrapper {
    display: flex;
    position: relative;
    width: 590px;
    align-items: center;
    padding: 0 24px 0 27px;
    border: 1px solid #7a7d82;
    border-radius: 30px;
    margin-right: 10px;
    transition: 0.3s border-color ease-in-out;

    .ant-form-item {
      flex: 1;
      margin: 0;
      &-control {
        margin-top: 0;
        .validation_block {
          margin-bottom: 0;
          .ant-input {
            background: none;
            padding-left: 22px;
            &:focus {
              border-color: transparent;
              box-shadow: none;
            }
          }
        }
      }
    }

    .icon__filter {
      cursor: pointer;
    }

    .icon__search {
      width: 1rem;
      height: 1rem;
    }

    .filters-form-card {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(calc(100% + 5px));
      background-color: $cardBG;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      z-index: 1000;
      padding: 30px 20px;
      min-width: 580px;

      .ant-form-item {
        margin: 0 0 1rem;
        &:not(.ant-radiogroup) {
          align-items: flex-start;
          .ant-form-item-label {
            margin-top: 8px;
          }
        }
        &-label > label {
          color: $lightGray;
          font-size: 1rem;
          &:after {
            margin: 0 14px 0 2px;
          }
        }
      }
      .ant-radio-group {
        flex-direction: row !important;
        .ant-radio-wrapper {
          margin-bottom: 0;
          margin-top: 0;
          span:not(.ant-radio) {
            color: $lightGray;
            margin-right: 1rem;
          }
        }
      }
    }
  }
  &__seats-amount {
    &-wrapper {
      display: flex;
    }
    &-input {
      max-width: 235px;
    }
    &-divider {
      margin-right: 10px;
      margin-top: 4px;
      color: $labelText;
      font-size: 14px;
      user-select: none;
    }
  }
  &__reset {
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: $red;
    cursor: pointer;
    margin-right: 10px;
    .icon__cross-rounded {
      height: 18px;
      margin-right: 5px;
    }
    span {
      line-height: 1;
    }
    &:hover {
      color: $lightRed;
    }
  }
}
