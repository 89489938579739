.room-form__modal {
  width: 505px;
  height: 90%;
  padding-right: 20px;
  &-title {
    @extend .modal_card__title;
    @extend .border_bottom;
    margin: 10px 10px 0 0;
  }
}
