.slide-enter {
  opacity: 0;
  margin-left: -1000px;
}

.slide-enter-active {
  opacity: 1;
  margin-left: 0;
  transition: opacity 300ms ease-in-out, margin-left 500ms;
}

.slide-enter-done {
  opacity: 1;
  margin-left: 0;
}

.slide-exit {
  opacity: 1;
  margin-left: 0;
}

.slide-exit-active {
  opacity: 0;
  margin-left: -2000px;
  transition: opacity 300ms ease-in-out, margin-left 500ms ease-in;
}

.slide-exit-done {
  opacity: 0;
  margin-left: -1000px;
  display: none !important;
}

.slide-appear {
  opacity: 0;
  margin-left: -1000px;
}

.slide-appear-active {
  opacity: 1;
  margin-left: 0;
  transition: opacity 300ms ease-in-out, margin-left 500ms;
}

.slide-appear-done {
  opacity: 1;
  margin-left: 0;
}

