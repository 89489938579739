.underlined-enter {
  width: 260px;
}

.underlined-enter-active {
  width: 100%;
  transition: width 300ms ease-out;
}

.underlined-enter-done {
  width: 100%;
}


.underlined-exit {
  width: 100%;
}

.underlined-exit-active {
  width: 260px;
  transition: width 200ms ease-out;
}

.underlined-exit-done {
  width: 260px;
}


.underlined-appear {
  width: 260px;
}

.underlined-appear-active {
  width: 100%;
  transition: width 300ms ease-out;
}

.underlined-appear-done {
  width: 100%;
}

// --------------

.underlined_title-enter {
  opacity: 0;
}

.underlined_title-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-out;
  transition-delay: .1s;
}

.underlined_title-enter-done {
  opacity: 1;
}


.underlined_title-exit {
  opacity: 1;
}

.underlined_title-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
  transition-delay: .1s;
}

.underlined_title-exit-done {
  opacity: 0;
}


.underlined_title-appear {
  opacity: 0;
}

.underlined_title-appear-active {
  opacity: 1;
  transition: opacity 200ms ease-out;
  transition-delay: .1s;
}

.underlined_title-appear-done {
  opacity: 1;
}
